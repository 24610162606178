lsfb-header {
  height: 100vh;
  margin: 0;
  background: yellow;
}

lsfb-header:before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 300px;
  background: #000;
  transform: skew(-30deg);
  transform-origin:top;
}


.lsfb-img {
  background:
    /* top, transparent black, faked with gradient */ 
    linear-gradient(
      rgba(0, 0, 0, 0.4), 
      rgba(0, 0, 0, 0.4)
    ),
    /* bottom, image */
    url(../images/LSFB/team-lsfb.jpeg);

    background-position: center;
    background-size: cover;
}